<template>
  <Container :title="$t('documents.titles.documents')" class="documents">
    <DocumentList/>
  </Container>
</template>

<script>
import DocumentList from "../components/DocumentList"

import Vue from 'vue'

export default Vue.extend({
  name: "Documents",
  components: {
    DocumentList,
  },
})
</script>

<style lang="scss">

</style>
