<template>
  <v-data-table
    ref="table"
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="size"
    :page="page"
    :server-items-length="count"
    :footer-props="{'items-per-page-options': [10, 20, 50, 100 ]}"
    @update:items-per-page="sizeChanged"
    @update:page="pageChanged"
  >
    <template #[`item.type`]="{ item }">
      <v-row class="ma-0 my-1 d-flex align-center">
        <span class="pr-1">{{ names[item.type] }}</span>
        <template v-if="item.investment">
          <router-link class="pr-1"
                       :to="{ name: 'Loan', params: { id: item.investment.loan.id, back: true } }"
          >
            ({{ $t('statements.loanId') }}: {{ item.investment.loan.reference_number }})
          </router-link>
        </template>
        <BaseLoanOriginatorChip v-if="item.investment" class="mr-1" :loan-originator="item.investment.loan.loan_originator"/>
        <BaseAutoInvestStrategyChip v-if="item.autoinvest_strategy" :style="{'max-width': '10rem'}" :strategy="item.autoinvest_strategy"/>
      </v-row>
    </template>
    <template #[`item.created_at`]="{ item }"><BaseDate :date="item.created_at"/></template>
    <template #[`item.actions`]="{ item }">
      <v-btn :href="item.file" target="_blank" download class="my-3" dark>
        {{ $t('documents.download') }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "DocumentList",
  data() {
    return {
      names: {
        // TODO this list is repeated multiple times across both frontends
        'investor_agreement_private': this.$t('documents.titles.investorAgreement'  ),
        'investor_agreement_company': this.$t('documents.titles.investorAgreement'  ),
        'autoinvest_agreement':       this.$t('documents.titles.autoinvestAgreement'),
        'claim_assignment':           this.$t('documents.titles.claimAssignment'    ),
        'claim_reassignment':         this.$t('documents.titles.claimReassignment'  ),
      },
      headers: [
        { text: this.$t('tableHeaders.name'),   value: 'type',        sortable: false },
        { text: this.$t('tableHeaders.date'),   value: 'created_at',  sortable: false },
        { text: '',                             value: 'actions',     sortable: false },
      ],
      count: null,
      page: 1,
      size: 10,
      items: [],
      loading: false,
    }
  },
  computed: {
    investmentAccount() {
      return this.$store.state.account
    },
  },
  watch: {
    investmentAccount: {
      immediate: true,
      handler(value, oldValue) {
        if (value?.id && value?.id != oldValue?.id) {
          this.fetch()
        }
      },
    },
  },
  activated() { this.fetch() },
  methods: {
    pageChanged(value) {
      this.page = value
      this.fetch()
      this.$vuetify.goTo(this.$refs.table)
    },
    sizeChanged(value) { this.size = value; this.fetch() },
    fetch() {
      this.loading = true
      this.axios
        .get('/documents/', { params: {
          page: this.page,
          size: this.size,
          investment_account: this.accountId,
        } })
        .then((response) => {
          this.loading = false
          this.items = response.data.results
          this.count = response.data.count
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>
